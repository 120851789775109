import { Chain } from '@chain-registry/types';
import { chains } from 'chain-registry';

export const MAINNET_CHAIN_ID = 'sgenet-1';
export const MAINNET_CHAIN_NAME = 'sge';
export const MAINNET_GRPC = 'https://grpc.sgenetwork.io/';
export const MAINNET_RPC = 'https://rpc.sgenetwork.io/';
export const MAINNET_REST = 'https://api.sgenetwork.io/';

export const TESTNET_CHAIN_ID = 'sge-network-4';
export const TESTNET_CHAIN_NAME = 'sgetestnet4';
export const TESTNET_GRPC = 'https://grpc.testnet.sixsigmasports.io/';
export const TESTNET_RPC = 'https://rpc.testnet.sixsigmasports.io/';
export const TESTNET_REST = 'https://api.testnet.sixsigmasports.io/';

export const STAGING_CHAIN_ID = 'stage-sgenetwork';
export const STAGING_CHAIN_NAME = 'sge-staging';
export const STAGING_CHAIN_PRETTY_NAME = 'SGE Staging';
export const STAGING_GRPC = 'https://grpc.staging.sixsigmasports.io/';
export const STAGING_RPC = 'https://rpc.staging.sixsigmasports.io/';
export const STAGING_REST = 'https://api.staging.sixsigmasports.io/';

const mainnetFromRegistry = chains.find((chain) => {
  return chain.chain_id === MAINNET_CHAIN_ID;
});

const testnetFromRegistry = chains.find((chain) => {
  return chain.chain_id === TESTNET_CHAIN_ID;
});

export const mainnet: Chain | undefined = mainnetFromRegistry
  ? {
      ...mainnetFromRegistry,
      apis: {
        ...mainnetFromRegistry.apis,
        rpc: [
          {
            address: MAINNET_RPC,
          },
        ],
        rest: [
          {
            address: MAINNET_REST,
          },
        ],
        grpc: [
          {
            address: MAINNET_GRPC,
          }
        ]
      },
    }
  : undefined;

export const testnet: Chain | undefined = testnetFromRegistry
  ? {
      ...testnetFromRegistry,
      chain_name: TESTNET_CHAIN_NAME,
      apis: {
        ...testnetFromRegistry.apis,
        rpc: [
          {
            address: TESTNET_RPC,
          },
        ],
        rest: [
          {
            address: TESTNET_REST,
          },
        ],
        grpc: [
          {
            address: TESTNET_GRPC,
          }
        ]
      },
    }
  : undefined;

export const staging: Chain | undefined = testnetFromRegistry
  ? {
      ...testnetFromRegistry,
      chain_id: STAGING_CHAIN_ID,
      chain_name: STAGING_CHAIN_NAME,
      pretty_name: STAGING_CHAIN_PRETTY_NAME,
      apis: {
        ...testnetFromRegistry.apis,
        rpc: [
          {
            address: STAGING_RPC,
          },
        ],
        rest: [
          {
            address: STAGING_REST,
          },
        ],
        grpc: [
          {
            address: STAGING_GRPC,
          }
        ]
      },
    }
  : undefined;
