import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type WalletData = {
  chainId: string;
  address: string;
  username: string;
};

export type State = {
  walletName: string | undefined;
  isConnecting: Record<string, boolean>;
  isDisconnecting: Record<string, boolean>;
  walletStates: Record<string, string | undefined>;
  walletMessages: Record<string, string | undefined>;
  walletData: Record<string, WalletData | undefined>;
};

const initialState: State = {
  walletName: undefined,
  isConnecting: {},
  isDisconnecting: {},
  walletStates: {},
  walletMessages: {},
  walletData: {},
};

const slice = createSlice({
  name: 'cosmosKitManager',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<string | undefined>) => {
      state.walletName = action.payload;
    },
    setWalletData: (
      state,
      action: PayloadAction<{
        walletName: string;
        data: WalletData | undefined;
      }>,
    ) => {
      state.walletData[action.payload.walletName] = action.payload.data;
    },
    setWalletMessage: (
      state,
      action: PayloadAction<{
        walletName: string;
        message: string | undefined;
      }>,
    ) => {
      state.walletMessages[action.payload.walletName] = action.payload.message;
    },
    setWalletState: (
      state,
      action: PayloadAction<{ walletName: string; state: string | undefined }>,
    ) => {
      state.walletStates[action.payload.walletName] = action.payload.state;
    },
    setIsConnecting: (
      state,
      action: PayloadAction<{ walletName: string; isConnecting: boolean }>,
    ) => {
      state.isConnecting[action.payload.walletName] =
        action.payload.isConnecting;
    },
    setIsDisconnecting: (
      state,
      action: PayloadAction<{ walletName: string; isDisconnecting: boolean }>,
    ) => {
      state.isDisconnecting[action.payload.walletName] =
        action.payload.isDisconnecting;
    },
  },
});

export const {
  setWallet,
  setWalletData,
  setWalletMessage,
  setWalletState,
  setIsConnecting,
  setIsDisconnecting,
} = slice.actions;
export default slice.reducer;
